import whenDomReady from 'when-dom-ready';

import isIe11 from '@utils/is-ie11';

async function initialise() {
  if (isIe11()) {
    const { default: svg4everybody } = await import('svg4everybody/dist/svg4everybody');
    svg4everybody();
  }
}

whenDomReady().then(initialise);
