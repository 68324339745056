const isIe11 = function isIE11() {
  const ieVersion = document.body.style.msTouchAction !== undefined;
  return ieVersion;
};

if (isIe11()) {
  const $body = document.querySelector('body');
  $body.classList.add('IE11');
}

export default isIe11;
