import whenDomReady from 'when-dom-ready';
import objectFitImages from 'object-fit-images';
import imagesLoaded from 'imagesloaded/imagesloaded';

import isIe11 from '@utils/is-ie11';

async function initialise() {
  if (isIe11()) {
    const someImages = document.querySelectorAll('.js-object-fit');
    Array.from(someImages).forEach(imageElement => {
      imagesLoaded(imageElement, () => {
        objectFitImages(imageElement);
      });
    });
  }
}

whenDomReady().then(initialise);
